import baseStyles from '../sass/base.scss';
import $ from "expose-loader?exposes=$,jQuery!jquery";
import 'selectric';
import "selectric/public/selectric.css";
import anime from 'animejs/lib/anime.es.js';
import mobileMenuJS from './includes/mobile_menu.js';
import customFancyboxJS from './includes/custom_fancybox.js';
import customScrollsJS from './includes/custom_scrolls.js';
import customTabsJS from './includes/custom_tabs.js';
import ratingFieldJS from './includes/rating_field.js';
import 'swiper/css/bundle';

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight();

$(document).ready(function() {
    $('.selectric-select').selectric({
        maxHeight: 360,
        disableOnMobile: false,
        nativeOnMobile: true
    });
});
