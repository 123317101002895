import Structure from '../modules/structure';

$.fn.ratingField = function() {

    var jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('ratingField', container, {
            input: 'input',
            stars: {
                selector: '.review-rating .star',
                many: true
            }
        }).structure;
        structure.stars.each(function() {
            const star = $(this);
            const link = new Structure('ratingField', star, {
                link: 'a',
            }).structure.link;
            const ratingValue = link.data('rating') ? link.data('rating') : -1;
            if (ratingValue < 1 || ratingValue > 5) {
                throw new Error(`ratingField: no valid data-rating in ${link}`);
            }
            console.log('ratingValue', ratingValue);
            star
            .on('mouseenter', function(e) {
                structure.stars.each(function( index ) {
                    const localStar = $(this);
                    if (index <= ratingValue -1) {
                        localStar.addClass('focus');
                    } else {
                        localStar.removeClass('focus');
                    }
                });
            })
            .on('mouseleave', function(e) {
                structure.stars.each(function( index ) {
                    const localStar = $(this);
                    localStar.removeClass('focus');
                });
            });
            link
            .on('click', function(e) {
                e.preventDefault();
                structure.input.val(ratingValue);
                structure.stars.each(function( index ) {
                    const localStar = $(this);
                    localStar.removeClass('selected');
                    if (index <= ratingValue-1) {
                        localStar.addClass('selected');
                    } 
                });
            });
        }); 
    });

    return this;
};

document.addEventListener('DOMContentLoaded', function() {
    $('form label.rating').ratingField();
});
